//@ts-ignore
import { utility } from "@tdt-global/utility";

export default function Root(props) {
  const { RenderIfToken } = utility;
  return (
    <RenderIfToken>
      <section>{props.name}</section>
    </RenderIfToken>
  );
}
